import { useTheme } from '@mui/material/styles';
import backgroundImageMain from '../videos/line-diving-first-frame.jpg';
import searchBackgroundVideo from '../videos/line-diving.mp4';

const LandingPageVideo = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '88vh',
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${backgroundImageMain})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      >
        <source src={searchBackgroundVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default LandingPageVideo;

/*
<video
  autoPlay
  loop
  poster={isIphone() ? backgroundImageMain : ''}
  muted
  style={{
    position: 'absolute',
    width: '100%',
    objectFit: 'cover',
    height: '88vh'
  }}
>
  {!isIphone() && (
    <source src={searchBackgroundVideo} type="video/mp4" />
  )}
</video>
*/
