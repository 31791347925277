import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  TextField,
  Avatar,
  Tab,
  Divider,
  useMediaQuery,
  Zoom,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardActionArea,
  CardMedia
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { Search, Check } from '@mui/icons-material';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  limit
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { logEvent, getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { isProfileReady, getLogo } from '../modules/utils';
import HeaderBar from '../components/HeaderBar';
import MemberCard from '../components/MemberCard';
import GooglePlaces from '../components/GoogleMaps';
import Footer from '../components/Footer';
// import scubaDivingImage from '../images/divers-with-fish.jpg';
// import underwaterPhotographyImage from '../images/underwater-photography.jpg';
import freeDivingImage from '../images/free-diving-girl.jpg';
// import boatImage from '../images/boat.jpg';
import whySynergyDiving from '../images/girl-snorkeling.jpg';
import member from '../images/freedom.jpg';
import Loader from '../components/Loader';
import AIResponseSection from '../components/AIResponseSection';
import LandingPageVideo from '../components/LandingPageVideo';

const App = () => {
  const [tab, setTab] = useState('1');
  const [members, setMembers] = useState();
  const [featuredMembers, setFeaturedMembers] = useState();
  const [featuredYoutube, setFeaturedYoutube] = useState();
  const [upcomingExperiences, setUpcomingExperiences] = useState();
  const [place, setPlace] = useState();
  const navigate = useNavigate();
  const theme = useTheme();

  const xsScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const getMembers = async () => {
      const membersSnapshot = await getDocs(
        collection(getFirestore(), 'members')
      );

      const { featuredMembers, media } = (
        await getDoc(doc(getFirestore(), 'settings', 'app'))
      ).data();
      console.log('loaded featured members', featuredMembers);
      setFeaturedMembers(featuredMembers);
      setFeaturedYoutube(media.youtubeEmbedUrl);

      const memberData = {};
      for (const member of membersSnapshot.docs) {
        // only show members with complete profiles
        if (isProfileReady(member.data())) {
          const { firstName, lastName, bio, timestamps } = member.data();
          memberData[member.id] = {
            firstName,
            lastName,
            bio,
            id: member.id,
            accountCreated: timestamps.accountCreated.toMillis()
          };
        }
      }

      console.log('loaded members', memberData);
      setMembers(memberData);
    };

    getMembers();
  }, []);

  useEffect(() => {
    const getUpcomingExperiences = async () => {
      // get the next 4 upcoming experiences
      const experiencesSnapshot = await getDocs(
        query(
          collection(getFirestore(), 'experiences'),
          where('timestamps.startDate', '>=', new Date()),
          limit(2)
        )
      );

      const experiencesData = {};
      const functions = getFunctions();

      const cleverAI = httpsCallable(functions, 'chatResponse');
      const maxImageDimension = process.env.REACT_APP_MAX_IMAGE_DIMENSION;

      for (const experience of experiencesSnapshot.docs) {
        const hype = await cleverAI({
          messages: [
            {
              role: 'system',
              content: 'You are a genius with marketing copy.'
            },
            {
              role: 'user',
              content: `Create an exciting sales pitch (without hash tags) in maximum 555 characters from: ${
                experience.data().description
              }`
            }
          ]
        });
        console.log('cleverAI', hype);

        const image = await getDownloadURL(
          ref(
            getStorage(),
            `experiences/${experience.data().memberId}/${
              experience.id
            }_${maxImageDimension}x${maxImageDimension}.jpeg`
          )
        );
        experiencesData[experience.id] = {
          ...experience.data(),
          hype: hype.data.content,
          image
        };
      }

      console.log('loaded experiences', experiencesData);
      setUpcomingExperiences(experiencesData);
    };

    getUpcomingExperiences();
  }, []);

  if (!members || !featuredMembers || !featuredYoutube) {
    return <Loader />;
  }

  const listItem = text => (
    <ListItem>
      <ListItemIcon>
        <Check />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );

  return (
    <Fragment>
      <HeaderBar />
      <Grid
        container
        justifyContent="center"
        sx={{
          p: 1,
          // backgroundImage: isIphone() ? `url(${backgroundImageMain})` : '',
          // backgroundSize: 'cover',
          height: '88vh',
          mt: 5
        }}
      >
        <LandingPageVideo />
        <Grid item md={8} lg={4} sx={{ zIndex: 1, mt: 11 }}>
          <Paper>
            <TabContext value={tab}>
              <TabList
                onChange={(event, newValue) => setTab(newValue)}
                centered
                variant="fullWidth"
              >
                <Tab label="Find experiences" value="1" />
                <Tab label="Search members" value="2" />
              </TabList>
              <TabPanel value="1">
                <GooglePlaces
                  label="Where do you want to go?"
                  setValue={setPlace}
                  value={place}
                />
                {place && (
                  <Box textAlign="center" mt={3}>
                    <Zoom in>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={!place}
                        size={xsScreen ? 'small' : 'medium'}
                        onClick={() => {
                          if (place) {
                            logEvent(getAnalytics(), 'search', {
                              search_term: place.description
                            });
                            navigate(`/results?placeId=${place.place_id}`);
                          }
                        }}
                        startIcon={<Search />}
                      >
                        Search
                      </Button>
                    </Zoom>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value="2">
                <Typography variant="body2" gutterBottom>
                  View a particular member...
                </Typography>
                <Autocomplete
                  disablePortal
                  options={Object.values(members).sort((a, b) =>
                    a.lastName.localeCompare(b.lastName)
                  )}
                  getOptionLabel={option =>
                    `${option.firstName} ${option.lastName}`
                  }
                  renderInput={params => (
                    <TextField {...params} label="Members" />
                  )}
                  onChange={(event, newValue) => {
                    navigate(`/profiles/${newValue.id}`);
                  }}
                />
                <Divider sx={{ margin: 3 }} />
                <Box textAlign="center">
                  <Typography variant="body2" gutterBottom>
                    or
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/members')}
                  >
                    View all members
                  </Button>
                </Box>
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          px: xsScreen ? 1 : 3,
          py: 8,
          backgroundColor: theme.palette.primary.light
        }}
      >
        <Grid item xs={12} sx={{ p: 1, mt: 1 }}>
          <Typography variant="h4" gutterBottom color="primary.contrastText">
            Questions?
          </Typography>
          <Typography variant="subtitle1" color="primary.contrastText">
            Our AI freediver can answer practically anything.
          </Typography>
        </Grid>
        <AIResponseSection />
      </Grid>

      <Grid
        container
        justifyContent="center"
        sx={{
          px: xsScreen ? 1 : 3,
          pt: 3
        }}
      >
        <Grid item xs={12} sx={{ p: 1, mt: 1 }}>
          <Typography variant="h4" gutterBottom>
            Upcoming experiences
          </Typography>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {upcomingExperiences ? (
              Object.keys(upcomingExperiences).map(upcomingExperienceId => (
                <Grid item xs={12} lg={6} key={upcomingExperienceId}>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      title={dayjs(
                        upcomingExperiences[
                          upcomingExperienceId
                        ].timestamps.startDate.toMillis()
                      ).format('LL')}
                      subheader={
                        upcomingExperiences[upcomingExperienceId].place.address
                      }
                    />
                    <CardMedia
                      sx={{ height: 555 }}
                      image={upcomingExperiences[upcomingExperienceId].image}
                      title={upcomingExperiences[upcomingExperienceId].title}
                    />
                    <CardContent>
                      <Typography variant="h6">
                        {upcomingExperiences[upcomingExperienceId].title}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        with{' '}
                        {
                          members[
                            upcomingExperiences[upcomingExperienceId].memberId
                          ].firstName
                        }{' '}
                        {
                          members[
                            upcomingExperiences[upcomingExperienceId].memberId
                          ].lastName
                        }
                      </Typography>
                      <Typography variant="body1">
                        {upcomingExperiences[upcomingExperienceId].hype}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ m: 1 }}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          navigate(
                            `/checkout/experience/${upcomingExperienceId}`
                          )
                        }
                      >
                        Learn more
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" sx={{ my: 3 }}>
                Loading upcoming experiences...
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        sx={{
          p: xsScreen ? 1 : 3,
          backgroundColor: theme.palette.primary.light
        }}
      >
        <Grid item xs={12} sm={5} sx={{ padding: 3 }}>
          <img
            src={getLogo()}
            alt="Synergy Diving logo"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={7} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h4" sx={{ mb: 5 }} color="primary.contrastText">
            What is Synergy Diving?
          </Typography>
          <Typography variant="h5" gutterBottom color="primary.contrastText">
            Synergy Diving is a platform to book diving experiences.
          </Typography>
          <Typography variant="h6" gutterBottom color="primary.contrastText">
            We actively support the freelance diving community.
          </Typography>
          <Paper sx={{ p: 3, my: xsScreen ? 3 : 8, mx: xsScreen ? 0 : 8 }}>
            <Typography variant="h6" gutterBottom>
              Ready to start working and earning money for yourself?
            </Typography>
            <Box textAlign="center" marginTop={3}>
              <Button
                variant="contained"
                onClick={() => navigate('/member/signup')}
              >
                Create your free account
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          p: xsScreen ? 1 : 3
        }}
      >
        <Grid item xs={12} lg={6} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Visual Library
          </Typography>
          <Typography variant="h6" gutterBottom>
            The underwater world is a visually stunning place.
          </Typography>
          <Typography variant="body1" gutterBottom>
            See our library of gorgeous photos and videos of underwater
            adventures.
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            onClick={() => navigate('/visual-media')}
          >
            View Our Visual Library
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ padding: 3 }}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="iframe"
                height="444"
                image={featuredYoutube}
                frameBorder={0}
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          p: xsScreen ? 1 : 3,
          backgroundColor: theme.palette.primary.light
        }}
      >
        <Grid item xs={12} sm={4} sx={{ padding: 3 }}>
          <img
            src={getLogo()}
            alt="Synergy Diving logo"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h4" sx={{ mb: 5 }} color="primary.contrastText">
            Synergy Diving Merch!
          </Typography>
          <Typography variant="h6" gutterBottom color="primary.contrastText">
            Want to have our brilliantly designed logo on some swag?
          </Typography>
          <Typography variant="body1" gutterBottom color="primary.contrastText">
            Custom design your t-shirt, get some stickers, and how about a
            custom wetsuit?
          </Typography>
          <Typography variant="body1" gutterBottom color="primary.contrastText">
            Proceeds all help support the running of the freelance diving
            community :)
          </Typography>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ mt: 3 }}
              onClick={() => navigate('/store')}
            >
              Visit store
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ p: xsScreen ? 1 : 3 }}>
        <Grid item xs={12}>
          <Typography variant={xsScreen ? 'h5' : 'h3'} sx={{ p: 1, mt: 1 }}>
            Member Benefits
          </Typography>
          <Stack
            direction={{ xs: 'column-reverse', lg: 'row' }}
            spacing={5}
            sx={{ p: 3 }}
          >
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                We support members in a variety of ways:
              </Typography>
              <List dense>
                {listItem('Every member is provided with a free account.')}
                {listItem('There are no sign-up or reoccurring fees.')}
                {listItem(
                  'Whatever experiences you have booked or products you sell, members keep all their earnings less a small credit card processing fee we pass on from our online payment processor.'
                )}
                {listItem(
                  'Free support from Synergy Diving should you need anything at all'
                )}
                {listItem(
                  'A beautifully generated profile (that will include your profile photo, bio, verifications, experiences you are offering, and products you are selling) that you can share with and link from your social media accounts.'
                )}
                {listItem(
                  'Complete freedom to create experiences that are unique as you are. How about a sunrise mermaid free diving experience that focuses on mental wellbeing?'
                )}
                {listItem(
                  'Industry leading secure and efficient payment processing; from credit card processing from customers, to paying you out directly to your bank account.'
                )}
                {listItem(
                  'No matter what country you are based in, we can help you setup an account to list experiences and get paid.'
                )}
              </List>
            </Box>
            <Box>
              <img
                src={member}
                style={{ width: '100%' }}
                alt="Synergy Diving member"
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {/*
      <Divider sx={{ mx: 8 }} />
      <Grid container sx={{ px: 3, py: 5 }}>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <Box>
              <img
                src={scubaDivingImage}
                style={{ width: '100%' }}
                alt="Synergy Diving scuba diving instructors"
              />
            </Box>
            <Box>
              <Typography gutterBottom variant="h6">
                Scuba Diving instructors
              </Typography>
              <Typography variant="body1" gutterBottom>
                As a scuba instructor, you can offer scuba courses, fun dives,
                or try dives.
              </Typography>
              <Typography variant="body1" gutterBottom>
                You can also sell merchandise from your profile.
              </Typography>
              <Typography variant="body1" gutterBottom>
                By listing directly through Synergy Diving, you can keep more of
                your earnings than through dive shops.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => navigate('/member/signup')}
              >
                Sign up for free
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      */}
      <Divider sx={{ mx: 8 }} />
      <Grid container sx={{ px: 3, py: 5 }}>
        <Grid item>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={3}>
            <Box>
              <Typography gutterBottom variant="h6">
                Freediving instructors
              </Typography>
              <Typography variant="body1" gutterBottom>
                As freedive instructors, you might run freediving courses, line
                dives, fun dives, try dives, and mermaid courses.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Members may also sell merchandise.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => navigate('/member/signup')}
              >
                Sign up for free
              </Button>
            </Box>
            <Box>
              <img
                src={freeDivingImage}
                style={{ width: '100%' }}
                alt="Synergy Diving freediving instructors"
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {/*
      <Divider sx={{ mx: 8 }} />
      <Grid container sx={{ px: 3, py: 5 }}>
        <Grid item>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <Box>
              <img
                src={underwaterPhotographyImage}
                style={{ width: '100%' }}
                alt="Synergy Diving underwater photographers"
              />
            </Box>
            <Box>
              <Typography gutterBottom variant="h6">
                Photographers
              </Typography>
              <Typography variant="body1" gutterBottom>
                As an underwater photographer, you may run experiences like
                underwater photoshoots.
              </Typography>
              <Typography variant="body1" gutterBottom>
                You might sell products such as prints, photoshoot 'vouchers',
                and any other relevant merchandise.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => navigate('/member/signup')}
              >
                Sign up for free
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ mx: 8 }} />
      <Grid container sx={{ px: 3, py: 5 }}>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={3}>
            <Box>
              <Typography gutterBottom variant="h6">
                Boat captains
              </Typography>
              <Typography variant="body1" gutterBottom>
                As a boat captain, you might run experiences that include dive
                charters, dolphin watching, whale tours, turtle tours, and
                sightseeing tours to name a few.
              </Typography>
              <Typography variant="body1" gutterBottom>
                As a boat captain you might offer boat hire by the hour, and
                sell other relevant merchandise.
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => navigate('/member/signup')}
              >
                Sign up for free
              </Button>
            </Box>
            <Box>
              <img
                src={boatImage}
                style={{ width: '100%' }}
                alt="Synergy Diving boat captains"
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
         */}
      <Grid
        container
        justifyContent="center"
        sx={{ px: 3, py: 5, backgroundColor: theme.palette.primary.light }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 5, color: theme.palette.primary.contrastText }}
        >
          Featured members
        </Typography>
        <Grid container spacing={2}>
          <MemberCard
            name={`${members[featuredMembers.newestMember].firstName} ${
              members[featuredMembers.newestMember].lastName
            }`}
            bio={members[featuredMembers.newestMember].bio}
            uid={featuredMembers.newestMember}
            key={'newest-member'}
            title="Welcome to our newest member!"
          />
          <MemberCard
            name={`${
              members[featuredMembers.memberWithNextExperience].firstName
            } ${members[featuredMembers.memberWithNextExperience].lastName}`}
            bio={members[featuredMembers.memberWithNextExperience].bio}
            uid={featuredMembers.memberWithNextExperience}
            key={'featured-member-experience'}
            title={`Next experience is with ${
              members[featuredMembers.memberWithNextExperience].firstName
            }!`}
          />
          <MemberCard
            name={`${members[featuredMembers.selected].firstName} ${
              members[featuredMembers.selected].lastName
            }`}
            bio={members[featuredMembers.selected].bio}
            uid={featuredMembers.selected}
            key={'featured-member'}
            title={`${
              members[featuredMembers.selected].firstName
            } is our featured member 🐬`}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ p: 3 }}>
        <Grid item xs={12} sm={6} sx={{ p: 1 }}>
          <Avatar
            src={whySynergyDiving}
            sx={{ width: '100%', height: 333 }}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ p: 2, mt: 1 }}>
          <Typography variant="h4" gutterBottom>
            Why use Synergy Diving?
          </Typography>
          <List dense>
            {listItem('Free registration')}
            {listItem('No commissions taken on bookings')}
            {listItem('Available worldwide')}
            {listItem('Simple and fast booking system')}
            {listItem('Customisable profiles')}
          </List>
          <Box textAlign="center" marginTop={3}>
            <Button
              variant="contained"
              onClick={() => navigate('/member/signup')}
            >
              Create a free account
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default App;
