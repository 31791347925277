import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Dialog,
  CardContent,
  Card,
  CardActionArea,
  CardMedia,
  CardHeader,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Container
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { LocationOn, Close } from '@mui/icons-material';
import { getFirestore, getDocs, collection } from 'firebase/firestore';
import HeaderBar from '../components/HeaderBar';
import Loader from '../components/Loader';
import Footer from '../components/Footer';

const VisualMedia = () => {
  const [imageUrl, setImageUrl] = useState();
  const [mediaItems, setMediaItems] = useState();
  const theme = useTheme();

  const MediaItem = ({ component, url, title, location, date, text }) => (
    <Grid item xs={12} lg={6}>
      <Card sx={{ backgroundColor: theme.palette.primary.dark }}>
        <CardHeader
          title={title}
          subheader={
            location ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <LocationOn fontSize="small" />
                <Typography>{location}</Typography>
              </Stack>
            ) : null
          }
        />
        <CardActionArea onClick={() => setImageUrl(url)}>
          <CardMedia
            component={component}
            height="444"
            image={url}
            frameBorder={0}
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {text}
          </Typography>
          <Typography variant="caption" component="div">
            {date}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  useEffect(() => {
    const getMedia = async () => {
      const media = await getDocs(collection(getFirestore(), 'media'));

      const allMedia = [];
      for (let mediaItem of media.docs) {
        allMedia.push({ ...mediaItem.data(), id: mediaItem.id });
      }
      setMediaItems(allMedia);
    };

    getMedia();
  }, []);

  if (!mediaItems) {
    return <Loader status="Loading beautiful things..." />;
  }

  const generateItems = type =>
    mediaItems
      .filter(item => item.type === type)
      .map(item => (
        <MediaItem
          key={item.id}
          component={item.url.includes('youtube') ? 'iframe' : 'img'}
          url={item.url}
          title={item.title}
          date={format(item.date.toDate(), 'PPP')}
          location={item.location}
          text={item.description}
        />
      ));

  return (
    <>
      <HeaderBar />
      <Grid container sx={{ mt: 8, p: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Visual Media
          </Typography>
          <Typography variant="body1" gutterBottom>
            A combination of professional development tips for freedivers,
            gorgeous videos, and stunning photos from our diving community!
          </Typography>
          <Divider sx={{ my: 3 }} />
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Typography variant="h4">Community Visual Media</Typography>
      </Container>
      <Grid container spacing={8} sx={{ p: 3 }}>
        {generateItems('community')}
      </Grid>
      <Container maxWidth="xl">
        <Divider sx={{ my: 3 }} />
        <Typography variant="h4">Professional Development</Typography>
      </Container>
      <Grid container spacing={8} sx={{ p: 3 }}>
        {generateItems('education')}
      </Grid>
      <Footer />
      <Dialog fullScreen open={!!imageUrl} onClose={() => setImageUrl(null)}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setImageUrl(null)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img src={imageUrl} alt="fullscreen" />
      </Dialog>
    </>
  );
};

export default VisualMedia;
